import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "dashboard", fluid: "", tag: "section" } },
        [
          _c("div", { staticClass: "banker-dashboard-grid" }, [
            _c("div", { staticClass: "banker-dashboard-section-values" }, [
              _c(
                "h1",
                { staticClass: "banker-dashboard-section-values-title" },
                [_vm._v(" " + _vm._s("DXA + " + _vm.banker.name) + " ")]
              ),
              _c(
                "p",
                { staticClass: "banker-dashboard-section-values-sub-title" },
                [_vm._v(" " + _vm._s(_vm.$t("banker_values_sub_title")) + " ")]
              ),
              _c(
                "div",
                { staticClass: "banker-dashboard-section-values-card-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "banker-dashboard-section-values-card" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("investment_quantify_title"))),
                      ]),
                      _vm.banker.totalInvestments
                        ? _c("h2", [
                            _vm._v(
                              " " + _vm._s(_vm.banker.totalInvestments) + " "
                            ),
                          ])
                        : _c("h2", [_vm._v("-")]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "banker-dashboard-section-values-cards" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "banker-dashboard-section-values-cards-title",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("investment_values")) + " "
                          ),
                        ]
                      ),
                      _c("CardFormattedCurrency", {
                        attrs: {
                          cardTitle: _vm.$t("total"),
                          totalInvested: _vm.banker.totalInvested,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "banker-dashboard-section-values-cards-title",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("allocations_pending")) + " "
                          ),
                        ]
                      ),
                      _c("CardFormattedCurrency", {
                        attrs: {
                          cardTitle: _vm.$t("total"),
                          totalInvested: _vm.banker.totalRequestPending,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", [
              _c(
                "h1",
                { staticClass: "banker-dashboard-title-section-links" },
                [_vm._v(" " + _vm._s(_vm.$t("invite_new_investors")) + " ")]
              ),
              _c(
                "p",
                { staticClass: "banker-dashboard-sub-title-section-links" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invite_new_investors_and_bankers_sub_title")
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.banker.registerUrl
                ? _c("div", { staticClass: "banker-dashboard-section-link" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invite_investor")))]),
                    _c("h2", [_vm._v(_vm._s(_vm.$t("register_investor")))]),
                    _c("p", [_vm._v(_vm._s(_vm.banker.registerUrl))]),
                    _c(
                      "div",
                      {
                        staticClass: "banker-dashboard-section-link-copy-link",
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function () {
                                return _vm.handleCopyUrl(_vm.banker.registerUrl)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("click_to_copy_link")) + " "
                            ),
                          ]
                        ),
                        _c("font-awesome-icon", {
                          attrs: {
                            icon: "fa-solid fa-copy",
                            width: "16px",
                            height: "16px",
                            color: "var(--primary)",
                          },
                          on: {
                            click: function () {
                              return _vm.handleCopyUrl(_vm.banker.registerUrl)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.carouselItems && _vm.carouselItems.length
                ? _c(
                    "div",
                    { staticClass: "carousel-container" },
                    [
                      _vm.gs.isMobile()
                        ? _c("h2", { staticClass: "insights-title" }, [
                            _vm._v(" " + _vm._s(_vm.$t("dxa_insights")) + " "),
                          ])
                        : _c("h2", { staticClass: "insights-title" }, [
                            _vm._v(_vm._s(_vm.$t("insights_dxa"))),
                          ]),
                      _c(
                        VCarousel,
                        {
                          attrs: { cycle: "", height: "255px" },
                          model: {
                            value: _vm.model,
                            callback: function ($$v) {
                              _vm.model = $$v
                            },
                            expression: "model",
                          },
                        },
                        _vm._l(_vm.carouselItems, function (carouselItem, i) {
                          return _c(
                            VCarouselItem,
                            {
                              key: i,
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpenVidoDialog(carouselItem)
                                },
                              },
                            },
                            [
                              _c(VSheet, { attrs: { height: "100%" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _c("img", {
                                        staticStyle: { "max-width": "100%" },
                                        attrs: {
                                          src: _vm.gs.get_photo_path(
                                            carouselItem.urlThumb
                                          ),
                                          alt: carouselItem.thumbFileName,
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]),
          _vm.users
            ? _c(
                VContainer,
                {
                  staticClass: "white mt-4 rounded",
                  attrs: { tag: "section", fluid: "" },
                },
                [
                  _c(
                    VRow,
                    [
                      _c("h4", { staticClass: "h4" }, [
                        _vm._v(_vm._s(_vm.$tc("user", 2))),
                      ]),
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2",
                          attrs: {
                            rounded: "",
                            color: "primary",
                            loading: _vm.allocationsReportDownloading,
                          },
                          on: { click: _vm.download_allocations_report },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-2" }, [
                            _vm._v("mdi-download-box"),
                          ]),
                          _c("span", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("download_allocations_report"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            rounded: "",
                            color: "primary",
                            loading: _vm.investorsReportDownloading,
                          },
                          on: { click: _vm.download_investors_report },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-2" }, [
                            _vm._v("mdi-download-box"),
                          ]),
                          _c("span", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v(_vm._s(_vm.$t("download_investors_report"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("InvestorsTable", { attrs: { Users: _vm.users } }),
                  _c(
                    VRow,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          staticStyle: { "margin-right": "44px" },
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/banker/users")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("see_more")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.videoDialog
            ? _c(
                VDialog,
                {
                  attrs: { scrollable: "" },
                  model: {
                    value: _vm.videoDialog,
                    callback: function ($$v) {
                      _vm.videoDialog = $$v
                    },
                    expression: "videoDialog",
                  },
                },
                [_c("VideoDialog", { attrs: { video: _vm.videoToOpen } })],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }